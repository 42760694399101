import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ProductDetailContainer } from './ProductDetail.styles';
import ErrorBoundary from '../../../../components/errorBoundary/ErrorBoundary';
import ProductDetailLightBox from './components/ProductDetailLightBox';
import { Product, ProductImage } from '../../../../domain/product/product.model';
import { Spinner } from '../../../../components';
import ImageSection from './components/ImageSection';
import InfoSection from './components/InfoSection';
import RelatedProducts from './components/RelatedProducts';
import { getByCriteria, getRelatedProducts } from '../../../../redux/products/products.thunks';

const ProductDetailPage = () => {
  const history = useHistory();
  const { name } = useParams<{ category: string; name: string }>();
  const [x, setX] = useState<number>(0);
  const [product, setProduct] = useState<Product>();
  const [relatedProducts, setRelatedProducts] = useState<Array<Product>>([]);
  const [imagesArr, setImagesArr] = useState<Array<ProductImage>>([]);
  const [showProductLightBox, setShowProductLightBox] = useState<boolean>(false);

  const showImgHandler = () => setShowProductLightBox(!showProductLightBox);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async () => {
      const { products } = await getByCriteria({
        field: 'name',
        value: name,
        operator: 'EQUAL',
        limit: 1,
      });
      if (products && products?.length > 0) {
        setProduct(products[0]);
        setImagesArr(
          products[0]?.images?.map((item: ProductImage) => ({
            id: item.id,
            name: item.name,
            src: item?.src,
            alt: item?.name,
          })),
        );
        const related = products[0]?.relatedIds ? await getRelatedProducts(products[0]?.relatedIds?.slice(0, 4)) : [];
        setRelatedProducts(related);
      }
      window.scrollTo(0, 0);
    })();
  }, [history.location.pathname]);
  return (
    <ErrorBoundary>
      {!product || imagesArr?.length === 0 ? (
        <Spinner top="35%" />
      ) : (
        <>
          {showProductLightBox && <ProductDetailLightBox onClose={showImgHandler} selectedIndex={x} data={imagesArr} />}
          <ProductDetailContainer>
            <ImageSection imagesArr={imagesArr} x={x} setX={setX} imageHandler={showImgHandler} />
            <InfoSection product={product} />
          </ProductDetailContainer>
          {relatedProducts && relatedProducts?.length > 0 && <RelatedProducts products={relatedProducts} />}
        </>
      )}
    </ErrorBoundary>
  );
};

export default ProductDetailPage;
